/* eslint-disable no-nested-ternary */
import { Options, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import { Currency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import {
    chartColors,
    ChartContext,
    ChartOptionsDefinitions,
    columnChartType,
    defalutLineWidth,
    defaultChartAxisTickLabelStyle,
    defaultChartAxisTitleStyle,
    defaultChartOptions,
    defaultZoneAxis,
    featuredLineWidth,
    HighchartsPlot,
    IChartBarDataSeries,
    IChartDataSeries,
    IChartProps,
    lineChartType,
    shortDashStyle,
} from '../ChartDefinitions';
import chartService from '../ChartService';

export interface IProjectionChartRawProps extends IChartProps {
    linesSeries: IChartDataSeries[];
    barSeries: IChartBarDataSeries;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    lineSeriesScreenPercent?: number;
    barSeriesScreenPercent?: number;
}

const lineSeriesScreenPercent = 60;
const lineSeriesScreenPercentWithoutBars = 90;
const lineSeriesBufferPercent = 5;
const barSeriesScreenPercent = 40;
const barSeriesBufferPercent = 5;

const defaultBarSeriesDecimalPlaces = 2;

const ProjectionChartRaw: React.FC<IProjectionChartRawProps> = (props: IProjectionChartRawProps) => {
    const [translations] = useLanguage();

    const linesPercent = props.lineSeriesScreenPercent ?? lineSeriesScreenPercent;
    const barPercent = props.barSeriesScreenPercent ?? barSeriesScreenPercent;

    const [forecastDataOn, setForecastDataOn] = useState<boolean>(true);
    const projectionChartDefaultOptions = useMemo<Options>(
        () => ({
            ...defaultChartOptions,
            yAxis: [
                {
                    title: {
                        text: '',
                        style: defaultChartAxisTitleStyle,
                    },
                    labels: {
                        format: '{value:{point.y: , .0f}',
                        style: defaultChartAxisTickLabelStyle,
                    },
                    opposite: !props.barSeries.isHidden,
                    gridLineWidth: props.barSeries.isHidden ? 1 : 0,
                    startOnTick: false,
                    endOnTick: false,
                },
                {
                    title: {
                        text: props.barSeries.label,
                        style: defaultChartAxisTitleStyle,
                    },
                    labels: {
                        format:
                            props.barSeries.format === 'currency'
                                ? `{value:{point.y: , .${props.barSeries.displayDecimalPlacesMaximum ?? defaultBarSeriesDecimalPlaces}f}`
                                : props.barSeries.format === 'percent'
                                ? '{value: , .Of}%'
                                : '{value: , .Of}', // TODO: Fix this -> Hungary Milk Equiv. Imports, Austria Fluid Imports
                        style: defaultChartAxisTickLabelStyle,
                    },
                    startOnTick: false,
                    endOnTick: false,
                },
            ],
            series: [],
        }),
        [props.barSeries, props.displayDecimalPlacesMaximum],
    );

    const [highchartOptions, setHighchartOptions] = useState<Options>(projectionChartDefaultOptions);

    const getDataSeriesDefinitions = useCallback(() => {
        const linesDataSeries = props.linesSeries.flatMap((lineSeries, index) => {
            const color = chartColors.lineChartColorsRuleSetOne[index % chartColors.lineChartColorsRuleSetOne.length];
            const primaryLine = index === 0 || lineSeries.isPrimaryLine;
            return [
                {
                    ...chartService.getDataSeriesBase(lineSeries, lineChartType, color),
                    yAxis: 0,
                    events: {
                        legendItemClick() {
                            loggingService.trackEventWithAnalytics(
                                EventActionsEnum.ButtonClick,
                                EventCategoriesEnum.LegendItemClicked,
                                lineSeries.label,
                                EventDataTargetsEnum.ProjectionGraph,
                            );
                        },
                    },
                    zoneAxis: defaultZoneAxis,
                    zones: [
                        {
                            value: 0,
                        },
                        {
                            dashStyle: shortDashStyle,
                        },
                    ],
                    lineWidth: primaryLine ? featuredLineWidth : defalutLineWidth,
                },
                // These forecast x-axis registrations are there just for the legend.
                chartService.getForecastDataSeriesBase(lineSeries, color, handleForecastClicked),
            ];
        });

        const barDataSeries = !props.barSeries.isHidden
            ? [
                  {
                      name: props.barSeries.label,
                      yAxis: 1,
                      type: columnChartType,
                      data: [] as HighchartsPlot[],
                      marker: {
                          symbol: 'square',
                          radius: 12,
                          fillColor: scssVariables.ruleSetOneBarColor1,
                          lineWidth: 1,
                          lineColor: scssVariables.ruleSetOneBarColor1,
                      },
                      zoneAxis: defaultZoneAxis,
                      color: scssVariables.ruleSetOneBarColor1,
                      events: {
                          legendItemClick() {
                              loggingService.trackEventWithAnalytics(
                                  EventActionsEnum.ButtonClick,
                                  EventCategoriesEnum.LegendItemClicked,
                                  props.barSeries.label,
                                  EventDataTargetsEnum.MultiAreaLineChart,
                              );
                          },
                      },
                      zones: [
                          {
                              value: 0,
                              border: '1px',
                              borderColor: scssVariables.ruleSetOneBarColor1,
                              color: scssVariables.ruleSetOneBarColor1,
                          },
                          {
                              border: '1px',
                              borderColor: scssVariables.ruleSetOneBarColor1,
                              color: scssVariables.plainWhite,
                          },
                      ],
                      visible: true,
                      showInLegend: true,
                  },
                  // This forecast x-axis registrations are there just for the legend.
                  {
                      name: props.barSeries.forecastLabel ?? `${props.barSeries.label} ${translations.words.forecast}`,
                      yAxis: 1,
                      marker: {
                          symbol: 'square',
                          radius: 12,
                          fillColor: scssVariables.plainWhite,
                          lineWidth: 1,
                          lineColor: scssVariables.ruleSetOneBarColor1,
                      },
                      events: {
                          legendItemClick() {
                              handleForecastClicked(this as unknown as ChartContext);
                          },
                      },
                      zoneAxis: defaultZoneAxis,
                      color: scssVariables.ruleSetOneBarColor1,
                      lineWidth: 0,
                      visible: true,
                      showInLegend: true,
                  },
              ]
            : [];

        return [...linesDataSeries, ...barDataSeries];
    }, [props.linesSeries, props.barSeries, forecastDataOn]);

    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        let newYAxis = [...(projectionChartDefaultOptions.yAxis as YAxisOptions[])!];
        let lineMinimumValue = Number.MAX_VALUE;
        let lineMaximumValue = Number.MIN_VALUE;
        const allSeries = [...props.linesSeries];
        const futureDate = new Date(2100, 0, 1);

        if (!props.barSeries?.isHidden) {
            allSeries.push(props.barSeries);
        } else {
            newYAxis = [newYAxis[0]];
        }

        const downloadData = chartService.getDownloadData(allSeries, undefined, props.displayDecimalPlacesMinimum);

        allSeries.forEach((series, index) => {
            const lastActualValueDataIndex = series.data.findLastIndex((x) => x.isActualValue);
            const firstForecastDate = series.data.length > lastActualValueDataIndex + 1 ? series.data[lastActualValueDataIndex]?.asOfDate : futureDate;
            const endIndex = forecastDataOn ? series.data.length : lastActualValueDataIndex + 1;

            (dataSeries[index * 2] as ChartOptionsDefinitions).data = series.data
                .slice(0, endIndex)
                .map((item) => ({ x: item.asOfDate.getTime(), y: item.value, isActualValue: item.isActualValue }));

            let localMinimumValue = Math.min(...series.data.map((x) => x.value));
            let localMaximumValue = Math.max(...series.data.map((x) => x.value));
            lineMinimumValue = Math.min(lineMinimumValue, localMinimumValue);
            lineMaximumValue = Math.max(lineMaximumValue, localMaximumValue);

            if (index < props.linesSeries.length) {
                // If we don't have the bars, we want to make the line series take up more room.
                const linesPercentOverride = props.barSeries?.isHidden ?? false ? lineSeriesScreenPercentWithoutBars : linesPercent;

                const range = Math.abs(lineMaximumValue - lineMinimumValue);
                newYAxis[0].max = lineMaximumValue + (range * lineSeriesBufferPercent) / 100.0;
                newYAxis[0].min = lineMinimumValue + range - range / ((linesPercentOverride + lineSeriesBufferPercent) / 100.0);
            } else {
                // If the values are all above or all below the axis, then we consider the min or max starting from 0.
                if (localMaximumValue < 0 && localMinimumValue < 0) {
                    localMaximumValue = 0;
                } else if (localMaximumValue > 0 && localMinimumValue > 0) {
                    localMinimumValue = 0;
                }

                const range = Math.abs(localMaximumValue - localMinimumValue);

                newYAxis[1].max = localMaximumValue - range + range / (((barPercent ?? barSeriesScreenPercent) + barSeriesBufferPercent) / 100.0);
                newYAxis[1].min = localMinimumValue === 0 ? 0 : localMinimumValue - (range * barSeriesBufferPercent) / 100.0;
            }

            // Push the point forward so it doesn't think the last actual is a forecast.
            if (firstForecastDate) {
                (dataSeries[index * 2] as ChartOptionsDefinitions).zones![0].value = firstForecastDate.getTime() + 1000;
            }

            (dataSeries[index * 2 + 1] as ChartOptionsDefinitions).visible = firstForecastDate !== futureDate && forecastDataOn;
            dataSeries[index * 2 + 1].showInLegend = firstForecastDate !== futureDate;
        });

        const newOptions = {
            ...projectionChartDefaultOptions,
            ...{ series: dataSeries },
            ...{ yAxis: newYAxis },
            ...{
                tooltip: {
                    formatter() {
                        const context = this as unknown as ChartContext;

                        if (context.series.index / 2 < props.linesSeries.length) {
                            return chartService.getTooltipText(context, {
                                nameOverride: newOptions.series![context.series.index + 1]!.name!,
                                displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum ?? 0,
                                displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum ?? 0,
                            });
                        }

                        return chartService.getTooltipText(context, {
                            nameOverride: newOptions.series![context.series.index + 1]!.name!,
                            displayDecimalPlacesMinimum: props.barSeries.displayDecimalPlacesMinimum ?? props.displayDecimalPlacesMinimum ?? 0,
                            displayDecimalPlacesMaximum: props.barSeries.displayDecimalPlacesMaximum ?? props.displayDecimalPlacesMaximum ?? 0,
                        });
                    },
                },
            },
            downloadData,
        };

        const maximumValue = Math.max(
            ...(newOptions.series as ChartOptionsDefinitions[]).flatMap((x) => (x.data ? x.data.map((y) => Math.abs((y as HighchartsPlot).y ?? 0)) : [])),
        );
        newOptions.yAxis[0].labels!.format = `{value:{point.y: , .${formattingService.getDisplayDecimalPlacesMinimumForCharts(maximumValue)}f}`;

        newOptions.yAxis[0]!.title!.text = chartService.getCurrencyAndUnitOfMeasureText(props.unitOfMeasure, props.currency);

        if (props.barSeries.axisLabel) {
            newOptions.yAxis[1]!.title!.text = props.barSeries.axisLabel;
        }

        setHighchartOptions(newOptions as Options);
    }, [forecastDataOn, props.linesSeries, props.barSeries, props.lineSeriesScreenPercent, props.barSeriesScreenPercent]);

    const handleForecastClicked = (context: ChartContext) => {
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ButtonClick,
            EventCategoriesEnum.ForcastDataToggled,
            forecastDataOn ? 'Off' : 'On',
            EventDataTargetsEnum.ProjectionGraph,
        );

        context.chart.series.forEach((item, index) => {
            if (index % 2 === 0 || index === context.index) {
                return;
            }

            if (forecastDataOn) {
                item.hide();
            } else {
                item.show();
            }
        });

        setForecastDataOn(!forecastDataOn);
    };

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default memo(ProjectionChartRaw);
